<template>
  <div class="main">
    <div class="allSubmit">
      <van-button type="primary" size="small" block @click="allSubmit()"
        >验收通过</van-button
      >
      <van-button type="danger" size="small" block @click="cancelSubmit"
        >整改</van-button
      >
    </div>
    <div class="main_cont">
      <template v-for="(items, index) in list" :key="index">
        <div class="checkBox" v-if="items.types == 5">
          <van-form @submit="sigleSubmit(items)">
            <van-cell-group inset>
              <div class="title">{{ index + 1 }}、{{ items.name }}</div>
              <van-field
                name="radio"
                label="选择项"
                :rules="[{ required: true, message: '请选择' }]"
              >
                <template #input>
                  <van-radio-group
                    direction="horizontal"
                    v-model="items.ischek"
                  >
                    <van-radio name="is_y" icon-size="20px">Y</van-radio>
                    <van-radio name="is_n" icon-size="20px">N</van-radio>
                    <van-radio name="is_suit" icon-size="20px">N/A</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <van-field
                v-if="items.ischek == 'is_n'"
                v-model="items.commention"
                rows="1"
                autosize
                label="备注"
                type="textarea"
                maxlength="500"
                placeholder="备注"
                show-word-limit
              />
              <van-field
                v-if="items.is_image == 1"
                name="uploader"
                label="图片上传"
              >
                <template #input>
                  <van-icon
                    name="chat-o"
                    @click="showImg(index)"
                    size="40"
                    :badge="items.attachment1 ? items.attachment1.length : 0"
                  />
                </template>
                <template #button>
                  <van-button
                    size="mini"
                    type="primary"
                    @click="takephoto(index)"
                    >拍照上传</van-button
                  >
                  <van-button
                    size="mini"
                    type="primary"
                    @click="checkphoto(index)"
                    >相册上传</van-button
                  >
                </template>
              </van-field>
            </van-cell-group>
          </van-form>
        </div>
      </template>
      <div style="margin-top:10px">
        <van-form style="width:95%;margin:0 auto;">
          <van-cell-group inset style="margin:0">
            <van-field
              v-model="query.job_msg"
              type="textarea"
              label="拒绝原因"
              placeholder="拒绝原因"
              autosize
              rows="1"
            />
            <van-field
              v-model="query.rectification_plan_date"
              is-link
              readonly
              name="datetimePicker"
              label="整改时间"
              placeholder="点击选择时间"
              @click="showPicker = true"
            />
            <van-field name="uploader" label="现场照片">
              <template #input>
                <van-icon
                  name="chat-o"
                  @click="showImg('null')"
                  size="40"
                  :badge="query.attachment2 ? query.attachment2.length : 0"
                />
              </template>
              <template #button>
                <van-button
                  size="mini"
                  type="primary"
                  @click="takephoto('null')"
                  >拍照上传</van-button
                >
                <van-button
                  size="mini"
                  type="primary"
                  @click="checkphoto('null')"
                  >相册上传</van-button
                >
              </template>
            </van-field>
          </van-cell-group>
        </van-form>
      </div>
    </div>
    <!-- 整改时间 -->
    <van-popup v-model:show="showPicker" position="bottom">
      <van-datetime-picker
        type="datetime"
        @confirm="onConfirm"
        @cancel="showPicker = false"
        :min-date="new Date(endDate)"
      />
    </van-popup>

    <handwritingHtml
      @writingCommit="writingCommit"
      ref="wirteDialog"
    ></handwritingHtml>
  </div>
</template>
<script>
/* eslint-disable */
import {onMounted,reactive,ref,getCurrentInstance} from 'vue';
import {useRoute,useRouter} from 'vue-router';
import Http from '../../../api/http';
import {Notify,Dialog} from 'vant';
import handwritingHtml from '../../../components/handwriting.vue';
export default {
    components:{handwritingHtml},
    setup() {
        const route = useRoute();
        const router = useRouter();
        const showPicker = ref(false);
        const endDate = ref(route.params.end);
        const query = reactive({
            method:'saveorupdate',
            t:'PTW_CETIFICATE',
            action:'fail',
            id:route.params.id,
            rectification_plan_date:'',
            attachment2:[],
            attachment3:[],
            job_msg:'',
            attachment1:[]
        });

        const $this = getCurrentInstance();
        const list = ref([]);
        const onConfirm = (data) =>{
            query.rectification_plan_date = Http.momentDate(data,'YYYY-MM-DD HH:mm:ss');
            showPicker.value = false;
        }
        const getlist = () =>{
            Http.get({
                method:'query',
                queryId:207,
                ptw_cetificate_id:route.params.id
            },false,true,true)
            .then(res=>{
                console.log(res);
                list.value = res;
            })
            .catch(()=>{})
        }


        // 上传签名
        const writingCommit = (res)=>{
            query.attachment3.push({document_path:res,document_code:'distribute'});
            Dialog.confirm({
                title: '标题',
                message: '是否确认验收不存在问题 ？',
            })
            .then(() => {
                Http.post({
                    method:'query',
                    queryId:'206',
                    request:list.value
                },false,true)
                .then(()=>{
                    query.action = 'success';
                    Http.post(query,false,true)
                    .then(()=>{
                        router.push('/ptwDetail/'+route.params.id)
                    })
                    .catch(()=>{});
                })
                .catch(()=>{})
                
            })
            .catch(() => {});
                
        }
        const allSubmit = () =>{
            var indexList = [];
            list.value.forEach((elem,index) =>{
                if(!elem.ischek){
                    indexList.push(index + 1);
                }
                
                elem.is_y = 0;elem.is_n = 0;elem.is_suit = 0;
                elem.ptw_cetificate_id = route.params.id
                elem[elem.ischek] = 1;
            });
            if(indexList.length > 0){
                return Notify({ type: 'danger', message: `第${indexList.join(',')}项未处理,请确认后在提交` });
            }
            $this.refs.wirteDialog.show();
            
        }
        // 
        const cancelSubmit = () =>{

            if(query.rectification_plan_date == ''){
                return Notify({ type: 'danger', message: `整改时间不能为空` });
            }
            if(query.job_msg==''){
                return Notify({ type: 'danger', message: `拒绝原因不能为空` });
            }
            Dialog.confirm({
                title: '标题',
                message: '确定需要进行整改 ？',
            })
            .then(() => {
                query.action = 'fail';
                Http.get(query,false,true)
                .then(()=>{
                    router.push('/ptwDetail/'+route.params.id)
                })
                .catch(()=>{})
            })
            .catch(() => {});
        }
        // 照片回调
        const imageurl = ref('');
        const onSuccess = (data,index) =>{
            imageurl.value = "data:image/png;base64," + data
            var file = Http.dataURLtoFile(imageurl.value);
            let formData = new FormData();
            formData.append('file', file, file.name);
            Http.fileuoload(formData)
            .then(res=>{
                // alert(res)
                if(index == 'null'){
                    query.attachment2.push({document_path:res})
                }
                else{
                    if(!list.value[index].attachment1){
                        list.value[index].attachment1 = [];
                        list.value[index].attachment1.push({document_path:res})
                    }else{
                        list.value[index].attachment1.push({document_path:res})
                    }
                }
                Http.loading.close()
            })
            .catch(()=>{
                alert(2)
                Http.loading.close()
            });
        }
        // 拍照
        const takephoto = (index) =>{
            navigator.camera.getPicture(
                function(data){
                    onSuccess(data,index)
                }, 
                function(err){
                    alert(err.toString())
                }, 
                {
                    quality: 50,
                    destinationType: Camera.DestinationType.DATA_URL,//返回FILE_URI类型
                    sourceType: Camera.PictureSourceType.CAMERA,//指定图片来自拍照
                    // cameraDirection: Camera.Direction.FRONT,//指定前后摄像头--好像没起作用
                    encodingType: Camera.EncodingType.PNG,
                    targetWidth: 150,
                    targetHeight: 150,
                    saveToPhotoAlbum:false
                });
        }
        // 从相册选择
        const checkphoto = (index) =>{
            navigator.camera.getPicture(
                function(data){
                    onSuccess(data,index)
                }, 
                function(err){
                    alert(err.toString())
                }, {
                quality: 50,
                destinationType: Camera.DestinationType.DATA_URL,//返回Base64编码字符串
                sourceType: Camera.PictureSourceType.SAVEDPHOTOALBUM,//指定图片来自相册
                encodingType: Camera.EncodingType.PNG,//指定返回图片是png格式还是jpeg
                targetWidth: 150,
                targetHeight: 150
            });
        }
        const showImg = (index) =>{
            const baseconfig = Http.config();
            var images = [];
            var imgs = [];
            if(index == 'null'){
                imgs = query.attachment2 || [];
            }else{
                imgs = list.value[index].attachment1 || [];
            }
            if(!imgs || imgs.length == 0){
                Notify({ type: 'warning', message: '暂无可查看图片' });
                return false;
            }else{
                imgs.forEach(elem =>{
                    images.push(baseconfig.baseUrl + elem.document_path);
                });
                ImagePreview({
                    images: images,
                    closeable:true,
                    startPosition: 1,
                    loop:true
                });
            }
        }
        onMounted(()=>{
            getlist();
        });
        return {
            list,
            allSubmit,
            cancelSubmit,
            showPicker,
            onConfirm,
            writingCommit,
            query,
            endDate,
            takephoto,
            showImg,
            checkphoto,
            onSuccess,
            imageurl,
        }
    },
}
</script>

<style scoped lang='less'>
.main_cont{
    height: calc(100% - 40px);
    overflow-y: auto;
    width: 100%;
}
.checkBox{
    width: 95%;
    background-color: #ffffff;
    border-radius: 5px;
    margin: 5px auto;
    .title{
        padding:10px;
        text-indent: 5px;
        font-size: 15px;
        font-family: 'blod';
        border-bottom: 1px solid #eeeeee;
    }
    .van-radio-group--horizontal{
        width: calc(100% - 20px);
        padding: 0 10px;
        justify-content: space-between;
        .van-radio{
            border-bottom: 0;
        }
    }
    .button{
        width:100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}
.allSubmit{
    height: 35px;
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'blod';
    font-size: 16px;
}
</style>